import React from 'react';
import './OfertaSekcja3.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faList, faArrowRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const OfertaSekcja3 = () => {
  const { t } = useTranslation();

  const openChatbot = () => {
    if (window.voiceflow && window.voiceflow.chat) {
      window.voiceflow.chat.open(); // Otwiera widget
    } else {
      console.error("Voiceflow widget nie został jeszcze załadowany!");
    }
  };

  return (
    <section className="oferta-sekcja-3">
      <div className="obrazek">
        <img src="/photo-waiters.png" alt={t('image_description')} />
      </div>
      <div className="tekst">
        <h2>{t('more_info_title')}</h2>
        <h3>{t('installation_time_title')}</h3>
        <p>{t('installation_time_description')}</p>
        <h3>{t('getting_started_title')}</h3>
        <p>{t('getting_started_description')}</p>
        <h3>{t('contact_now_title')}</h3>
        <p><strong>{t('phone_label')}:</strong> <a href="tel:+48502600739">{t('phone_number')}</a></p>
        <p><strong>{t('email_label')}:</strong> <a href="mailto:FelizTradeLTD@proton.me">{t('email_address')}</a></p>
            {/* Nowy Przycisk "Schedule a Call" */}
            <button className="section-btn" onClick={openChatbot}>
              <FontAwesomeIcon icon={faCalendarAlt} /> Schedule a Call
            </button>
      </div>
    </section>
  );
};

export default OfertaSekcja3;
