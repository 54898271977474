import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown, faChevronUp  } from '@fortawesome/free-solid-svg-icons'; // Import ikon dla hamburgera
import { faBoxOpen, faFilePdf, faHandshake, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [showLanguages, setShowLanguages] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Nowy stan
  const [isOfferOpen, setIsOfferOpen] = useState(false); // Nowy stan dla rozwijanego menu
  const [isDemoOpen, setIsDemoOpen] = useState(false); // Nowy stan dla rozwijanego menu

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleOffer = () => setIsOfferOpen(!isOfferOpen);
  const toggleDemo = () => setIsDemoOpen(!isDemoOpen);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowLanguages(false);
  };

  const currentLanguage = i18n.language.toUpperCase();

  return (
    <header className="header">
      <div className="logoDiv">
        <Link to="/">
          <img src="logo512.png" className="logoHeader" alt="Logo Feliztrade" />
        </Link>
      </div>
      <div className="menu-toggle" onClick={toggleMenu}>
        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
      </div>
      <nav className={`menu ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className="row justify-content-center g-2">
          <div className="col-6 col-md-3">
            <Link className="btn btn-outline-light w-100 text-center" onMouseEnter={() => setIsOfferOpen(true)} onMouseLeave={() => setIsOfferOpen(false)}>
                  <FontAwesomeIcon icon={faBoxOpen} /> {t('offer')} 
                  <FontAwesomeIcon icon={isOfferOpen ? faChevronUp : faChevronDown} className="offer-icon" />
                {isOfferOpen && (
                  <div className="dropdown-menu">
                    <Link to="/restaurant-app" className="dropdown-item">Restaurant APP</Link>
                    <Link to="/chatbot-ai" className="dropdown-item">Chatbot AI</Link>
                  </div>
                )}
            </Link>
          </div>


          <div className="col-6 col-md-3">
            <Link className="btn btn-outline-light w-100 text-center" to="/Kontakt">
              <FontAwesomeIcon icon={faHandshake} /> {t('contact')}
            </Link>
          </div>
        </div>

      </nav>
      <div className="language-toggle">
            <div className="divtoggle">
        <div
          className="language-display"
          onClick={() => setShowLanguages(!showLanguages)}
        >
          {currentLanguage}
          <span className={`arrow ${showLanguages ? 'up' : 'down'}`}></span>
        </div>
        {showLanguages && (
          <div className="language-menu">
            <button onClick={() => changeLanguage('en')}><span>🇬🇧</span> English</button>
            <button onClick={() => changeLanguage('es')}><span>🇪🇸</span> Español</button>
            <button onClick={() => changeLanguage('pl')}><span>🇵🇱</span> Polski</button>

          </div>
        )}
              </div>
      </div>
    </header>
  );
};

export default Header;
