import React, { useRef, useEffect } from 'react';
import './Uni.css';
import { Canvas, useFrame, useThree } from 'react-three-fiber';
import * as THREE from 'three';
import { OrbitControls, PerspectiveCamera, Stars, Icosahedron } from '@react-three/drei';


// Importowanie własnego materiału
import myTexture from './safemoon-textures.jpg';

// Tworzenie materiału z teksturą
const myMaterial = new THREE.MeshBasicMaterial({
  map: new THREE.TextureLoader().load(myTexture),
});

// Ustawienie pozycji kamery na początku animacji
const initialCameraPosition = [6, 12, -172];

// // Funkcja animująca kamery przez pierwsze 3 sekundy
// const animateCamera = (camera, elapsedTime) => {

// if (elapsedTime <= 6) {

//     // Obliczenie czasu do 3 sekund
//     const timeTo3Seconds = Math.min(6, elapsedTime) / 6;

//     // Obliczenie nowej pozycji kamery za pomocą interpolacji liniowej (LERP)
//     const newPosition = [
//       THREE.MathUtils.lerp(initialCameraPosition[0], 6, timeTo3Seconds),
//       THREE.MathUtils.lerp(initialCameraPosition[1], 0, timeTo3Seconds),
//       THREE.MathUtils.lerp(initialCameraPosition[2], 6.0, timeTo3Seconds), // Przesunięcie w kierunku z-owego
//     ];

//     // Ustawienie nowej pozycji kamery
//     camera.position.set(...newPosition);
//   }
// };

const animateCamera = (camera, elapsedTime) => {

  // Sprawdzenie, czy upłynęły 3 sekundy
  if (elapsedTime <= 6) {
    // Obliczenie czasu do 3 sekund
    const timeTo3Seconds = elapsedTime / 6;

    // Funkcja Ease Out (kwadratowa)
    const easedTime = 1 - Math.pow(1 - timeTo3Seconds, 2);

    // Obliczenie nowej pozycji kamery za pomocą interpolacji z efektem Ease Out
    const newPosition = [
      THREE.MathUtils.lerp(initialCameraPosition[0], 9, easedTime),
      THREE.MathUtils.lerp(initialCameraPosition[1], 0, easedTime),
      THREE.MathUtils.lerp(initialCameraPosition[2], 56, easedTime), // Przesunięcie w kierunku z-owego
    ];

    // Ustawienie nowej pozycji kamery
    camera.position.set(...newPosition);
  }
};



const Uni = () => {
  const icoRef = useRef();
  const cameraRef = useRef();


  return (
    <div className="uni-container">
      <Canvas className="uni-canvas">

        <PerspectiveCamera 
          makeDefault position={initialCameraPosition} 
          ref={cameraRef} 
        />

        <Stars />

        <Icosahedron
          args={[9, 32]}
          position={[0, -12.3, 0]}
          ref={icoRef}
          material={myMaterial}
        />

        <OrbitControls />
        
        <RotationAnimation icoRef={icoRef} />

        <StarsRotationAnimation />
        
        <React.Suspense fallback={null}>
          <Scene />
        </React.Suspense>
      
      </Canvas>

    </div>
  );
};

const Scene = () => {
  const camera = useThree((state) => state.camera);

  // Użycie hooka useFrame do animowania kamery
  useFrame(({ clock }) => {
    animateCamera(camera, clock.elapsedTime);
  });

  return null;
};

const RotationAnimation = ({ icoRef }) => {
  useFrame(() => {
    if (icoRef.current) {
      icoRef.current.rotation.y += 0.0015; // Obracamy Icosahedrona wokół osi Y
    }
  });

  return null;
};

const StarsRotationAnimation = () => {
  useFrame(({ scene }) => {
    scene.children.forEach(child => {
      if (child.type === 'Points') {
        child.rotation.y += 0.0003;
      }
    });
  });

  return null;
};



export default Uni;
