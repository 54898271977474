import React from 'react';
import './Opis.css';
import { useTranslation } from 'react-i18next';

const Opis = () => {
  const { t } = useTranslation();

  return (
    <section className="opis" id="featureDescription">
      
      <h2>{t('financial_benefits_title')}</h2>
      <ul>
        <li><strong>{t('optimization_title')}:</strong> {t('optimization_text')}</li>
        <li><strong>{t('financial_savings_title')}:</strong> {t('financial_savings_text')}</li>
        <li><strong>{t('annual_savings_title')}:</strong> {t('annual_savings_text')}</li>
        <li><strong>{t('error_minimization_title')}:</strong> {t('error_minimization_text')}</li>
      </ul>

      <h2>{t('app_features_title')}</h2>
      
      <h3>{t('for_customers_title')}</h3>
      <ul>
        <li><strong>{t('interactive_menu_title')}:</strong> {t('interactive_menu_text')}</li>
        <li><strong>{t('cart_and_orders_title')}:</strong> {t('cart_and_orders_text')}</li>
        <li><strong>{t('order_status_title')}:</strong> {t('order_status_text')}</li>
        <li><strong>{t('google_reviews_title')}:</strong> {t('google_reviews_text')}</li>
      </ul>

      <h3>{t('for_admins_title')}</h3>
      <ul>
        <li><strong>{t('menu_management_title')}:</strong> {t('menu_management_text')}</li>
        <li><strong>{t('table_management_title')}:</strong> {t('table_management_text')}</li>
        <li><strong>{t('events_and_promotions_title')}:</strong> {t('events_and_promotions_text')}</li>
        <li><strong>{t('order_history_title')}:</strong> {t('order_history_text')}</li>
      </ul>

      <h3>{t('for_waiters_title')}</h3>
      <ul>
        <li><strong>{t('order_view_title')}:</strong> {t('order_view_text')}</li>
        <li><strong>{t('kitchen_view_title')}:</strong> {t('kitchen_view_text')}</li>
        <li><strong>{t('real_time_updates_title')}:</strong> {t('real_time_updates_text')}</li>
        <li><strong>{t('order_history_waiters_title')}:</strong> {t('order_history_waiters_text')}</li>
      </ul>

      <section className="section technologia">
        <h2>{t('technology_title')}</h2>
        <ul>
          <li>{t('technology_text')}</li>
        </ul>
      </section>

      <section className="section bezpieczenstwo">
        <h2>{t('security_title')}</h2>
        <ul>
          <li>{t('security_customer_data_title')}</li>
          <li>{t('security_alcohol_verification_title')}</li>
        </ul>
      </section>
    </section>
  );
};

export default Opis;
