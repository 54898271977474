import React, { useState } from 'react';
import './OfertaSekcja1.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faList, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const OfertaSekcja1 = () => {
  const { t } = useTranslation();

  // Nowa funkcja do przewijania do sekcji
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="oferta-sekcja-1">
      <div className="tekst">
        <h2>{t('restaurant_app_title')}</h2>
        <p>{t('restaurant_app_description')}</p>
        
        <div className="buttons-container">
          {/* Przycisk do sekcji 2 - Cennik */}
          <button className="section-btn" onClick={() => scrollToSection('ofertaSekcja2')}>
            <FontAwesomeIcon icon={faFileInvoiceDollar} /> {t('pricing')}
          </button>
          
          {/* Przycisk do sekcji 2 - Opis funkcji */}
          <button className="section-btn" onClick={() => scrollToSection('featureDescription')}>
            <FontAwesomeIcon icon={faList} /> {t('feature_description')}
          </button>
        </div>
      </div>

      <div className="obrazek">
        <img src="/waiter-taps.png" alt="Oferta" />
        <div className="demo-button-container">
          <button className="section-btn demo-btn" onClick={() => window.open('https://restaurantfeliz-demo.onrender.com/admin', '_blank')}>
            {t('demo')}
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default OfertaSekcja1;
