import React, { useState } from 'react';
import './ChatbotFeliz.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faList, faArrowRight, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const OfertaSekcja1 = () => {
  const { t } = useTranslation();

  // Nowa funkcja do przewijania do sekcji
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const openChatbot = () => {
    if (window.voiceflow && window.voiceflow.chat) {
      window.voiceflow.chat.open(); // Otwiera widget
    } else {
      console.error("Voiceflow widget nie został jeszcze załadowany!");
    }
  };


  return (
    <>
      <section className="oferta-sekcja-1">
        <div className="tekst">
          <h2>{t('feliz_chatbot_title')}</h2>
          <p>{t('feliz_chatbot_description')}</p>
          
          <div className="buttons-container">
            {/* Przycisk do sekcji 2 - Cennik */}
            <button className="section-btn" onClick={() => scrollToSection('ofertaSekcja2')}>
              <FontAwesomeIcon icon={faFileInvoiceDollar} /> {t('pricing')}
            </button>
            
            {/* Przycisk do sekcji 2 - Opis funkcji */}
            <button className="section-btn" onClick={() => scrollToSection('featureDescription')}>
              <FontAwesomeIcon icon={faList} /> {t('feature_description')}
            </button>
          </div>
        </div>

        <div className="obrazek">
          <img src="/felizbot.png" alt="Oferta" />
          <div className="demo-button-container">
            <button className="section-btn demo-btn" onClick={openChatbot}>
              {t('demo')}
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </section>

      <section className="opis" id="featureDescription">
        
        <h2>{t('feliz_financial_benefits_title')}</h2>
        <ul>
          <li><strong>{t('feliz_error_minimization_title')}:</strong> {t('feliz_error_minimization_text')}</li>
          <li><strong>{t('feliz_optimization_title')}:</strong> {t('feliz_optimization_text')}</li>
          <li><strong>{t('feliz_financial_savings_title')}:</strong> {t('feliz_financial_savings_text')}</li>
          <li><strong>{t('feliz_annual_savings_title')}:</strong> {t('feliz_annual_savings_text')}</li>
        </ul>

        <h2>{t('app_features_title')}</h2>
        
        <ul>
          <li><strong>{t('lead_capture_title')}:</strong> {t('lead_capture_text')}</li>
          <li><strong>{t('appointment_setting_title')}:</strong> {t('appointment_setting_text')}</li>
          <li><strong>{t('business_agent_title')}:</strong> {t('business_agent_text')}</li>
        </ul>


        <section className="section technologia">
          <h2>{t('technology_title')}</h2>
          <ul>
            <li>{t('chatbot_technology_text')}</li>
          </ul>
        </section>

        <section className="section bezpieczenstwo">
          <h2>{t('security_title')}</h2>
          <ul>
            <li>{t('security_customer_data_title')}</li>
          </ul>
        </section>
      </section>

      <section className="oferta-sekcja-2" id="ofertaSekcja2">
        <h1>{t('pricing_title')}</h1>
        <h2>{t('feliz_basic_package_title')}</h2>
        <div className="oferta-content">
          <div className="oferta-tekst">
            <h3>{t('activation_cost')}</h3>
            <p>{t('feliz_basic_package_activation_cost')}</p>
            <h3>{t('maintenance_cost')}</h3>
            <p>{t('feliz_basic_package_maintenance_cost')}</p>
          </div>
          <div className="oferta-funkcje">
            <h3>{t('features')}</h3>
            <ul>
              <li>{t('appointment_setting_function')}</li>
              <li>{t('appointment_setting_function2')}</li>
              <li>{t('appointment_setting_function3')}</li>
            </ul>
          </div>
        </div>
        <h2>{t('feliz_basic2_package_title')}</h2>
        <div className="oferta-content">
          <div className="oferta-tekst">
            <h3>{t('activation_cost')}</h3>
            <p>{t('feliz_basic2_package_activation_cost')}</p>
            <h3>{t('maintenance_cost')}</h3>
            <p>{t('feliz_basic2_package_maintenance_cost')}</p>
          </div>
          <div className="oferta-funkcje">
            <h3>{t('features')}</h3>
            <ul>
              <li>{t('gen_lead_function')}</li>
              <li>{t('gen_lead_function2')}</li>
              <li>{t('gen_lead_function3')}</li>
              <li>{t('gen_lead_function4')}</li>
            </ul>
          </div>
        </div>
        <h2>{t('feliz_support_package_title')}</h2>
        <div className="oferta-content">
          <div className="oferta-tekst">
            <h3>{t('activation_cost')}</h3>
            <p>{t('feliz_support_package_activation_cost')}</p>
            <h3>{t('maintenance_cost')}</h3>
            <p>{t('feliz_support_package_maintenance_cost')}</p>
          </div>
          <div className="oferta-funkcje">
            <h3>{t('features')}</h3>
            <ul>
              <li>{t('support_function')}</li>
              <li>{t('support_function2')}</li>
              <li>{t('support_function3')}</li>
              <li>{t('support_function4')}</li>
              <li>{t('support_function5')}</li>
            </ul>
          </div>
        </div>
        <h2>{t('feliz_combo_package_title')}</h2>
        <div className="oferta-content">
          <div className="oferta-tekst">
            <h3>{t('activation_cost')}</h3>
            <p>{t('feliz_combo_package_activation_cost')}</p>
            <h3>{t('maintenance_cost')}</h3>
            <p>{t('feliz_combo_package_maintenance_cost')}</p>
          </div>
          <div className="oferta-funkcje">
            <h3>{t('features')}</h3>
            <ul>
              <li>{t('combo_function')}</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="oferta-sekcja-3">
        <div className="obrazek">
          <img src="/workshop-image.png" alt={t('image_description')} />
        </div>
        <div className="tekst">
          <h2>{t('more_info_title')}</h2>
          <h3>{t('installation_time_title')}</h3>
          <p>{t('feliz_installation_time_description')}</p>
          <h3>{t('getting_started_title')}</h3>
          <p>{t('feliz_getting_started_description')}</p>
          <h3>{t('contact_now_title')}</h3>
          <p><strong>{t('phone_label')}:</strong> <a href="tel:+48502600739">{t('phone_number')}</a></p>
          <p><strong>{t('email_label')}:</strong> <a href="mailto:FelizTradeLTD@proton.me">{t('email_address')}</a></p>
            {/* Nowy Przycisk "Schedule a Call" */}
            <button className="section-btn" onClick={openChatbot}>
              <FontAwesomeIcon icon={faCalendarAlt} /> Schedule a Call
            </button>
        </div>
      </section>
    </>
  );
};

export default OfertaSekcja1;
