import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Importowanie Routera i tras
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Uni from './components/Uni/Uni';
import Kontakt from './components/Kontakt/Kontakt'; // Import komponentu
import Opis from './components/OfertaRestaurant/Opis';
import OfertaSekcja1_1 from './components/OfertaRestaurant/OfertaSekcja1_1';
import OfertaSekcja2_1 from './components/OfertaRestaurant/OfertaSekcja2_1';
import OfertaSekcja3_1 from './components/OfertaRestaurant/OfertaSekcja3_1';
import ChatbotFeliz from './components/ChatbotFeliz/ChatbotFeliz';
// import Podstrona4 from './components/Podstrona4';
import './App.css';

function App() {


  return (
    <Router>
      <div className="App">
        <Uni /> {/* Element stały, wyświetlany zawsze */}
        <Header /> {/* Nagłówek, który jest widoczny na każdej podstronie */}
        <Routes>
          <Route path="/" element={<Hero />} /> {/* Strona główna */}
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/restaurant-app" element={
            <div>
              <OfertaSekcja1_1 />
              <Opis />
              <OfertaSekcja2_1 />
              <OfertaSekcja3_1 />
            </div>
          } />
          <Route path="/chatbot-ai" element={
            <div>
              <ChatbotFeliz />
            </div>
          } />
        </Routes>

      </div>
    </Router>
  );
}

export default App;
